import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import "./Checkout.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { message } from "antd";
import { setUser } from "../redux/features/userSlice";
import CheckIcon from "@mui/icons-material/Check";
import CryptoJS from "crypto-js";

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const { discount } = useSelector((state) => state.discount);
  const [cart, setCart] = useState(
    JSON.parse(localStorage.getItem("cart")) || []
  );
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [total, setTotal] = useState(0);
  const [orderSuccess, setOrderSuccess] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [mode, setMode] = useState("wallet");

  const secretKey = "REDLINE@#$123";
  const decryptPrice = (encryptedPrice) => {
    if (!secretKey) {
      console.error("Secret key is not defined");
      return encryptedPrice;
    }
    const bytes = CryptoJS.AES.decrypt(encryptedPrice, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  const calculateTotal = () => {
    const newTotal = cart.reduce(
      (acc, item) => acc + parseInt(decryptPrice(item.price)),
      0
    );
    setTotal(newTotal);
  };

  const generateOrderId = () => {
    const now = new Date();
    const year = now.getFullYear().toString().slice(-2);
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const date = now.getDate().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");
    const numbers = "0123456789";
    const randomNumbers = Array.from({ length: 4 }, () =>
      numbers.charAt(Math.floor(Math.random() * numbers.length))
    );
    const orderId = `${year}${month}${date}${seconds}${randomNumbers.join("")}`;
    setOrderId(orderId);
  };

  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
        } else {
          localStorage.removeItem("token");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    calculateTotal();
    getUserData();
  }, [cart]);

  useEffect(() => {
    generateOrderId();
  }, []);

  function checkPlaceOrder(e) {
    if (cart[0]?.api === "yes") {
      if (cart[0]?.apiName === "yokcash") {
      } else if (cart[0]?.apiName === "smileOne") {
        if (mode === "upi") {
          handleSmileOneUpiOrder(e);
        } else {
          handleSmileOneWalletOrder(e);
        }
      } else if (cart[0]?.apiName === "moogold") {
        if (mode === "upi") {
          handleMoogoldUpiOrder(e);
        } else {
          handleMoogoldWalletOrder(e);
        }
      }
    } else {
      if (mode === "upi") {
        handleUpiOrder(e);
      } else {
        handleWalletOrder(e);
      }
    }
  }
  // SMILE
  const handleSmileOneUpiOrder = async (e) => {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: decryptPrice(cart[0]?.price),
        product_name: cart[0]?.region,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://redlineshop.in/api/payment/check-api-upi-order?orderId=${orderId}`,
        txn_note:
          cart[0].userId.trim() +
          "@" +
          cart[0].zoneId.trim() +
          "@" +
          decryptPrice(cart[0].productId) +
          "@" +
          cart[0].name +
          "@" +
          cart[0].amount,
      };

      setLoader(true);
      const response = await axios.post(
        "/api/payment/create-api-upi-order",
        paymentObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
        localStorage.removeItem("cart");
        setLoading(false);
      } else {
        message.error(response.data.message);
        setLoading(false);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  const handleSmileOneWalletOrder = async (e) => {
    e.preventDefault();
    try {
      const orderObject = {
        orderId: orderId,
        userid: cart[0]?.userId,
        zoneid: cart[0]?.zoneId,
        productid: decryptPrice(cart[0]?.productId),
        region: cart[0]?.region,
        customer_email: user && user?.email,
        customer_mobile: user && user?.mobile,
        pname: cart[0]?.name,
        amount: cart[0]?.amount,
        price: decryptPrice(cart[0]?.price),
      };

      setLoading(true);
      setLoader(true);
      const res = await axios.post(
        "/api/payment/place-order-from-wallet",
        orderObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        localStorage.removeItem("cart");
        setLoading(false);
        setOrderSuccess(true);
      } else {
        setLoader(false);
        setLoading(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  // MOOGOLD
  async function handleMoogoldUpiOrder(e) {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: decryptPrice(cart[0]?.price),
        product_name: cart[0].gameName,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://redlineshop.in/api/moogold/check-moogold-upi-order?orderId=${orderId}`,
        txn_note:
          cart[0]?.userId +
          "@" +
          cart[0]?.zoneId +
          "@" +
          decryptPrice(cart[0].productId) +
          "@" +
          cart[0].name +
          "@" +
          cart[0].amount,
      };

      setLoader(true);
      const response = await axios.post(
        "/api/payment/create-api-upi-order",
        paymentObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.data.success && response.data.data.status) {
        localStorage.removeItem("cart");
        window.location.href = response.data.data.results.payment_url;
        setLoading(false);
      } else {
        message.error(response.data.message);
        setLoading(false);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  }
  async function handleMoogoldWalletOrder(e) {
    e.preventDefault();
    try {
      const orderObject = {
        api: "yes",
        order_id: orderId,
        txn_amount: decryptPrice(cart[0]?.price),
        product_name: cart[0]?.amount + "@" + cart[0].gameName,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        txn_note:
          cart[0]?.userId +
          "@" +
          cart[0]?.zoneId +
          "@" +
          decryptPrice(cart[0].productId) +
          "@" +
          cart[0].name,
      };

      setLoading(true);
      setLoader(true);
      const res = await axios.post(
        "/api/moogold/place-moogold-from-wallet",
        orderObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        localStorage.removeItem("cart");
        localStorage.setItem("orderProcess", "no");
        setLoading(false);
        setOrderSuccess(true);
      } else {
        setLoading(false);
        setLoader(false);
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
      setLoading(false);
    }
  }

  // MANUAL
  const handleUpiOrder = async (e) => {
    e.preventDefault();
    try {
      const playerId =
        cart[0]?.playerId !== "" ? cart[0]?.playerId : cart[0]?.userId;
      const paymentObject = {
        order_id: orderId,
        txn_amount: decryptPrice(cart[0]?.price),
        product_name: cart[0]?.name,
        customer_name: user?.fname,
        customer_mobile: user?.mobile,
        customer_email: user?.email,
        callback_url: `https://redlineshop.in/api/payment/check-manual-upi-order?orderId=${orderId}`,
        txn_note: playerId + "@" + cart[0]?.amount,
      };
      setLoader(true);
      const response = await axios.post(
        "/api/payment/create-manual-upi-order",
        paymentObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
        localStorage.removeItem("cart");
        setLoading(false);
      } else {
        message.error(response.data.message);
        setLoading(false);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  const handleWalletOrder = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const orderObject = {
        api: "no",
        orderId: orderId,
        userid: cart[0]?.userId,
        zoneid: cart[0]?.zoneId,
        productid: decryptPrice(cart[0]?.productId),
        region: cart[0]?.region,
        customer_email: user && user?.email,
        customer_mobile: user && user?.mobile,
        pname: cart[0]?.name,
        amount: cart[0]?.amount,
        price: decryptPrice(cart[0]?.price),
      };
      setLoader(true);
      const res = await axios.post(
        "/api/payment/place-order-non-api",
        orderObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setLoading(false);
        message.success(res.data.message);
        localStorage.removeItem("cart");
        navigate("/orders");
      } else {
        message.error(res.data.message);
        setLoading(false);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (mode === "wallet") {
      const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = "Are you sure you want to quit?";
        return "Are you sure you want to quit?";
      };
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [mode]);

  return (
    <Layout>
      {orderSuccess ? (
        <div className="order-success-container">
          <CheckIcon className="icon" />
          <span className="text-muted">Hey! {user?.fname}</span>
          <h4 className="my-1">Thank you for ordering!</h4>
          <span className="text-muted text-center">
            We have received your order and it will completed instantly!
          </span>
          <button
            onClick={() => {
              navigate("/user-dashboard");
              setOrderSuccess(false);
            }}
            className="add-to-cart-btn"
          >
            Check Order Status
          </button>
        </div>
      ) : loading ? (
        <div className="order-placing-loader">
          <div class="me-2 spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          Order Processing
        </div>
      ) : (
        <div className="container checkout-container">
          <div className="customer-form">
            {!user && (
              <h5>
                Already a customer? <Link to="/login">Login</Link>
              </h5>
            )}
            {user && (
              <div>
                <h5>Account Details</h5>
                <div className="row">
                  <div className="mb-3 col-12 col-sm-12 col-md-6 col-lg-6">
                    <label className="form-label" htmlFor="">
                      Name :
                    </label>
                    <h5>{user && user?.fname}</h5>
                  </div>
                  <div className="mb-3 col-12 col-sm-12 col-md-6 col-lg-6">
                    <label className="form-label" htmlFor="">
                      Email :
                    </label>
                    <h5>{user && user?.email}</h5>
                  </div>
                  <div className="mb-3 col-12 col-sm-12 col-md-6 col-lg-6">
                    <label className="form-label" htmlFor="">
                      Mobile :
                    </label>
                    <h5>{user && user?.mobile}</h5>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* CART  */}
          <div className="checkout-product-details">
            <div className="checkout-item-container">
              <div className="d-flex justify-content-between">
                <span>Product</span>
                <span>Subtotal</span>
              </div>
              <hr />
              {cart &&
                cart.map((item, index) => {
                  return (
                    <div key={index} className="checkout-item">
                      <span>{item.name}</span>
                      <span>{decryptPrice(item.price)}</span>
                    </div>
                  );
                })}
              <hr />
              <div className="checkout-item">
                <span>
                  <b>Wallet Balance</b>
                </span>
                <span>
                  <b>{user ? user?.balance : <Link to="/login">Login</Link>}</b>
                </span>
              </div>
              <div className="checkout-item">
                <span>
                  <b>Total</b>
                </span>
                <span>
                  <b>Rs. {total}</b>
                </span>
              </div>
              <div className="checkout-item">
                <span>
                  <b>Payment Mode</b>
                </span>
                <div className="d-flex gap-3">
                  {user?.reseller === "no" && (
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="paymentMode"
                        id="upiRadio"
                        value="upi"
                        checked={mode === "upi"}
                        onChange={() => setMode("upi")}
                      />
                      <label className="form-check-label" htmlFor="upiRadio">
                        UPI
                      </label>
                    </div>
                  )}
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="paymentMode"
                      id="walletRadio"
                      value="wallet"
                      checked={mode === "wallet"}
                      onChange={() => setMode("wallet")}
                    />
                    <label className="form-check-label" htmlFor="walletRadio">
                      Wallet
                    </label>
                  </div>
                </div>
              </div>
              {user?.balance >= total && mode === "wallet" && (
                <div className="checkout-item">
                  <span>Balance after order</span>
                  <span>
                    <b>Rs. {user?.balance - total}</b>
                  </span>
                </div>
              )}
            </div>
            {user && mode === "upi" ? (
              <button
                disabled={loader}
                onClick={checkPlaceOrder}
                className="w-100 add-to-cart-btn"
              >
                Place Order
              </button>
            ) : user?.balance < total ? (
              <button
                onClick={() => navigate("/wallet")}
                className="w-100 add-to-cart-btn"
              >
                Topup your wallet
              </button>
            ) : user && user?.balance >= total ? (
              <button
                disabled={loader}
                onClick={checkPlaceOrder}
                className="w-100 add-to-cart-btn"
              >
                Place Order
              </button>
            ) : (
              <button
                onClick={() => navigate("/login")}
                className="w-100 add-to-cart-btn"
              >
                Login to Continue
              </button>
            )}
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Checkout;
