import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import axios from "axios";
import { message } from "antd";
import "./HeroSection.css";
import IMAGES from "../../img/image";

const HeroSection = () => {
  const arrowRef = useRef();

  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    speed: 500,
    nextArrow: false,
    prevArrow: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {/* Desktop and Tablet  */}
      <div className="container-fluid hero-container">
        <Slider ref={arrowRef} {...settings}>
          <div className="p-3">
            <img src={IMAGES.b1} className="d-block w-100" alt="banners" />
          </div>
          <div className="p-3">
            <img src={IMAGES.b2} className="d-block w-100" alt="banners" />
          </div>
          <div className="p-3">
            <img src={IMAGES.b3} className="d-block w-100" alt="banners" />
          </div>
        </Slider>
        <div className="hero-slider-btns">
          <div onClick={() => arrowRef.current.slickPrev()}>
            <KeyboardArrowLeftIcon className="icon arrow-left" />
          </div>
          <div onClick={() => arrowRef.current.slickNext()}>
            <KeyboardArrowRightIcon className="icon arrow-right" />
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
