import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../Footer/Footer.css";
import EmailIcon from "@mui/icons-material/Email";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import { useSelector } from "react-redux";
import FacebookSharpIcon from "@mui/icons-material/FacebookSharp";

const Footer = () => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <React.Fragment>
      <div className="container-fluid footer-container">
        <div className="wa-icon-cont">
          <Link to="https://wa.me/918337855703" target="_blank">
            <WhatsAppIcon className="icon" />
          </Link>
        </div>
        {location.pathname !== "/cart" && (
          <div className="cart-icon-cont wa-icon-cont">
            <LocalMallIcon onClick={() => navigate("/cart")} className="icon" />
          </div>
        )}
        <div className="row">
          <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-4">
            <div className="footer-logo">
              <b>
                REDLINE<span className="ffca00">SHOP</span>
              </b>
            </div>
            <span>
              <small>
                Fuel your gaming adventures with ease! Explore our top-up
                website for instant access to game credits, currency, and items.
                Elevate your gaming experience today with seamless transactions
                and a wide range of top-up options. Level up your gaming arsenal
                now!
              </small>
            </span>
            <br />
            <br />
            <span>+91-8337855703</span>
            <br />
            {/* <span>Manipur , Bishnupur District, 795134</span> */}
          </div>
          <div className="px-lg-5 quick-links col-6 col-sm-6 col-md-3 col-lg-3 mb-4">
            <h6>Quick Links</h6>
            <hr />
            <ul>
              {!user && (
                <>
                  <li>
                    <Link to="/login">Login</Link>
                  </li>
                  <li>
                    <Link to="/register">Register</Link>
                  </li>
                </>
              )}
              {/* <li>
                <Link to="/about">About us</Link>
              </li> */}
              <li>
                <Link to="/contact">Contact</Link>
              </li>
              <li>
                <Link to="/track-order">Track Order</Link>
              </li>
            </ul>
          </div>
          <div className="col-6 col-sm-6 col-md-3 col-lg-3 mb-4">
            <h6>Important Pages</h6>
            <hr />
            <ul>
              {/* <li>
                <Link to="/faq">Frequently Asked Questions</Link>
              </li> */}
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms">Terms & Conditions</Link>
              </li>
              <li>
                <Link to="/refund-policy">Refund Policy</Link>
              </li>
            </ul>
          </div>
          <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-4">
            <h6>Connect here</h6>
            <hr />
            <div className="social-media-links">
              <Link
                target="_blank"
                to="https://www.instagram.com/officiallyredline"
              >
                <InstagramIcon className="icon" />
              </Link>
              <Link
                target="_blank"
                to="https://www.facebook.com/OfficiallyRedline"
              >
                <FacebookSharpIcon className="icon" />
              </Link>
              <Link target="_blank" to="https://youtube.com/@RedlineTVofficial">
                <YouTubeIcon className="icon" />
              </Link>
              <Link target="_blank" to="https://x.com/Redline_TVML">
                <TwitterIcon className="icon" />
              </Link>
              <Link target="_blank" to="https://wa.me/918337855703">
                <WhatsAppIcon className="icon" />
              </Link>
              <Link target="_blank" to="mailto:submityourquerry@yahoo.com">
                <EmailIcon className="icon" />
              </Link>
            </div>
          </div>
          <hr />
          <span className="text-center">
            <small>
              All Rights Reserved © 2023 |{" "}
              <Link to="" style={{ color: "#ffca00" }}>
                REDLINE SHOP
              </Link>{" "}
            </small>
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
