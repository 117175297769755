import React from "react";
import Layout from "../components/Layout/Layout";
import "./Terms.css";

const ShippingPolicy = () => {
  return (
    <Layout>
      <div className="bg-heading">
        <h4>
          <b>Terms and Conditions of Use</b>
        </h4>
      </div>
      <div className="terms-container">
        <p>
          Welcome to Redlineshop.in! These Terms and Conditions govern your use
          of our website; by accessing this website, you accept these terms and
          conditions in full. If you disagree with these terms and conditions or
          any part of these terms and conditions, you must not use this website.
        </p>
        <h5>Introduction</h5>
        <p>
          The following terminology applies to these Terms and Conditions,
          Privacy Statement and Disclaimer Notice and any or all Agreements:
          "Client", "You" and "Your" refers to you, the person accessing this
          website and accepting the Company's terms and conditions. "The
          Company", "Ourselves", "We", "Our" and "Us", refers to Redlineshop.in.
          "Party", "Parties", or "Us", refers to both the Client and ourselves,
          or either the Client or ourselves. All terms refer to the offer,
          acceptance and consideration of payment necessary to undertake the
          process of our assistance to the Client in the most appropriate
          manner, whether by formal meetings of a fixed duration, or any other
          means, for the express purpose of meeting the Client's needs in
          respect of provision of the Company's stated services/products, in
          accordance with and subject to, prevailing law of India. Any use of
          the above terminology or other words in the singular, plural,
          capitalization and/or he/she or they, are taken as interchangeable and
          therefore as referring to same.
        </p>
        <h5>License to Use Website</h5>
        <p>
          Unless otherwise stated, Redlineshop.in and/or its licensors own the
          intellectual property rights in the website and material on the
          website. Subject to the license below, all these intellectual property
          rights are reserved. You may view, download for caching purposes only,
          and print pages or other content from the website for your own
          personal use, subject to the restrictions set out below and elsewhere
          in these terms and conditions.
        </p>
        <h5>You must note:</h5>
        <ul>
          <li>
            1. Republish material from this website (including republication on
            another website) Sell, rent or sub-license material from the website
          </li>
          <li>2. Show any material from the website in public</li>
          <li>
            3. Reproduce, duplicate, copy or otherwise exploit material on this
            website for a commercial purpose
          </li>
          <li>4. Edit or otherwise modify any material on the website</li>
          <li>
            5. Redistribute material from this website, except for content
            specifically and expressly made available for redistribution
          </li>
          <li>
            6. Where content is specifically made available for redistribution,
            it may only be redistributed within your organization.
          </li>
        </ul>
        <h5>Acceptable Use</h5>
        <p>
          You must not use this website in any way that causes, or may cause,
          damage to the website or impairment of the availability or
          accessibility of Redlineshop.in or in any way which is unlawful,
          illegal, fraudulent or harmful, or in connection with any unlawful,
          illegal, fraudulent or harmful purpose or activity. You must not use
          this website to copy, store, host, transmit, send, use, publish or
          distribute any material which consists of (or is linked to) any
          spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit
          or other malicious computer software. You must not conduct any
          systematic or automated data collection activities (including without
          limitation scraping, data mining, data extraction and data harvesting)
          on or in relation to this website without Redlineshop.in's express
          written consent.
        </p>
        <h5>Limitation of Liability</h5>
        <p>
          To the maximum extent permitted by applicable law, in no event shall
          Redlineshop.in, nor any of its officers, directors, employees, agents,
          or shareholders be liable for any special, incidental, indirect, or
          consequential damages whatsoever (including, but not limited to,
          damages for loss of profits, loss of data or other information, for
          business interruption, for personal injury, loss of privacy arising
          out of or in any way related to the use of or inability to use the
          website), even if Redlineshop.in or a Redlineshop.in authorized
          representative has been notified orally or in writing of the
          possibility of such damage.
        </p>
        <h5>Changes to Terms</h5>
        <p>
          Redlineshop.in reserves the right, in its sole discretion, to change
          the Terms under which Redlineshop.in's website is offered. The most
          current version of the Terms will supersede all previous versions.
          Redlineshop.in encourages you to periodically review the Terms to stay
          informed of our updates.
        </p>
        <h5>Contact Information</h5>
        <p>
          If you have any questions or concerns about these Terms and
          Conditions, please contact us at submityourquery@yahoo.com.
        </p>
        <p>
          By using this website, you signify your acceptance of these terms and
          conditions. If you do not agree to these terms and conditions, please
          do not use our website. Your continued use of the website following
          the posting of changes to these terms will be deemed your acceptance
          of those changes.
        </p>
      </div>
    </Layout>
  );
};

export default ShippingPolicy;
