import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import "./AdminDashboard.css";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import StayCurrentPortraitIcon from "@mui/icons-material/StayCurrentPortrait";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import HelpIcon from "@mui/icons-material/Help";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [data, setData] = useState(null);
  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState(null);
  const [queries, setQueries] = useState(null);
  const [topUsers, setTopUsers] = useState(null);
  const [toggle, setToggle] = useState(true);
  const [totalOrders, setTotalOrders] = useState(null);
  const [totalSales, setTotalSales] = useState(null);
  const [totalProfit, setTotalProfit] = useState(null);
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const [fromDate, setFromDate] = useState(getTodayDate());
  const [toDate, setToDate] = useState(getTodayDate());
  const [monthlyOrdersData, setMonthlyOrdersData] = useState({
    labels: [],
    data: [],
  });
  const [monthlySalesData, setMonthlySalesData] = useState({
    labels: [],
    data: [],
  });
  // Balance
  const [smileBalance, setSmilebalance] = useState("");
  const [moogoldBalance, setMoogoldBalance] = useState("");
  const [indianSmartBalance, setIndianSmartBalance] = useState("");

  const getDashboard = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/admin/get-dashboard", {
        params: { fromDate, toDate },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setData(res.data.data.reverse());
        setOrders(res.data.orders);
        setProducts(res.data.products);
        setQueries(res.data.queries);
        setTopUsers(res.data.topUsers);
        setTotalOrders(res.data.totalOrders);
        setTotalSales(res.data.totalSales);
        setTotalProfit(res.data.totalProfit);

        const { monthlyOrders, monthlySales } = res.data;

        // Update state with fetched data
        setMonthlyOrdersData({
          labels: monthlyOrders.labels,
          data: monthlyOrders.data,
        });

        setMonthlySalesData({
          labels: monthlySales.salesLabels,
          data: monthlySales.salesData,
        });

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getDashboard();
  }, []);

  const handleSearch = () => {
    if (!fromDate || !toDate) {
      message.error("Please select both from and to dates");
      return;
    }
    getDashboard();
  };

  // const getSmilebalance = async () => {
  //   try {
  //     const res = await axios.get("/api/admin/smile-balance", {
  //       headers: {
  //         Authorization: "Bearer " + localStorage.getItem("token"),
  //       },
  //     });
  //     if (res.data.success) {
  //       setSmilebalance(res.data.data);
  //     } else {
  //       message.error(res.data.message);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const getMoogoldBalance = async () => {
  //   try {
  //     const res = await axios.get("/api/admin/moogold-balance", {
  //       headers: {
  //         Authorization: "Bearer " + localStorage.getItem("token"),
  //       },
  //     });
  //     if (res.data.success) {
  //       setMoogoldBalance(res.data.data);
  //     } else {
  //       message.error(res.data.message);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   getSmilebalance();
  //   getMoogoldBalance();
  // }, []);

  return (
    <AdminLayout>
      <div className="page-title">
        <h3 className="m-0">Dashboard</h3>
      </div>
      <hr />
      <div className="filter-container">
        <div className="field">
          <input
            onChange={(e) => setFromDate(e.target.value)}
            className="form-control"
            type="date"
            name="fromDate"
          />
        </div>
        <div className="field">
          <input
            onChange={(e) => setToDate(e.target.value)}
            className="form-control"
            type="date"
            name="fromDate"
          />
        </div>
        <button onClick={handleSearch} className="search-btn">
          Search
        </button>
        {loading ? (
          <div class="spinner-grow spinner-grow-sm" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="admin-dashboard-container p-0">
        {/* SMILE  */}
        {/* <div className="dash-card" onClick={() => navigate("/admin-orders")}>
          <div className="count">
            <h1 className="m-0">
              {loading ? (
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                <b>{smileBalance || 0}</b>
              )}
            </h1>
            <span className="title">Smile Coin</span>
          </div>
          <AttachMoneyIcon className="icon" />
        </div> */}
        {/* MOOOGLD  */}
        {/* <div className="dash-card" onClick={() => navigate("/admin-orders")}>
          <div className="count">
            <h1 className="m-0">
              {loading ? (
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                <b>{moogoldBalance || 0}</b>
              )}
            </h1>
            <span className="title">Moogold</span>
          </div>
          <AttachMoneyIcon className="icon" />
        </div> */}
        <div className="dash-card" onClick={() => navigate("/admin-orders")}>
          <div className="count">
            <h1 className="m-0">
              {loading ? (
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                <b>{totalOrders || 0}</b>
              )}
            </h1>
            <span className="title">Total Orders</span>
          </div>
          <PointOfSaleIcon className="icon" />
        </div>
        <div className="dash-card" onClick={() => navigate("/admin-products")}>
          <div className="count">
            <h1 className="m-0">
              {loading ? (
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                <b>{products?.length || 0}</b>
              )}
            </h1>
            <span className="title">Total Products</span>
          </div>
          <StayCurrentPortraitIcon className="icon" />
        </div>
        <div className="dash-card" onClick={() => navigate("/admin-payments")}>
          <div className="count">
            <h1 className="m-0">
              {loading ? (
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                <b>{totalSales || 0}</b>
              )}
            </h1>
            <span className="title">Total Sales</span>
          </div>
          <MonetizationOnIcon className="icon" />
        </div>
        <div className="dash-card" onClick={() => navigate("/admin-queries")}>
          <div className="count">
            <h1 className="m-0">
              <h1 className="m-0">
                {loading ? (
                  <div class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <b>
                    {queries?.filter((item) => {
                      return item.status === "pending";
                    }).length || 0}
                  </b>
                )}
              </h1>
            </h1>
            <span className="title">Queries</span>
          </div>
          <HelpIcon className="icon" />
        </div>
      </div>
      <div className="admin-recent-things">
        <div className="recent-orders">
          <h5>Recent Orders</h5>
          <hr />
          <table className="table ">
            <thead>
              <tr>
                <th>Order Id</th>
                <th>Email</th>
                <th>Total</th>
                <th>Date</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {data
                ?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <small>{item?.orderId}</small>
                      </td>
                      <td>
                        <small>{item?.customer_email}</small>
                      </td>
                      <td>
                        <small>{item?.price}</small>
                      </td>
                      <td>
                        <small>
                          {new Date(item?.createdAt).toLocaleString("default", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })}
                        </small>
                      </td>
                      <td>
                        <RemoveRedEyeIcon
                          onClick={() =>
                            navigate(`/admin-view-order/${item?.orderId}`)
                          }
                          className="text-success icon"
                        />
                      </td>
                    </tr>
                  );
                })
                .slice(0, 5)}
            </tbody>
          </table>
        </div>
        <div className="recent-queries">
          <h5>Recent Queries</h5>
          <hr />
          <table className="table ">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {queries &&
                queries
                  .map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <small>{item?.name}</small>
                        </td>
                        <td>
                          <small>{item?.email}</small>
                        </td>
                        <td>
                          <small>{item?.mobile}</small>
                        </td>
                        <td>
                          <button
                            className="register-btn p-1"
                            onClick={() => navigate("/admin-queries")}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    );
                  })
                  .slice(0, 5)}
            </tbody>
          </table>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminDashboard;
