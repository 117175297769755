import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import { message } from "antd";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./AdminUsers.css";

const AdminProduct = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [products, setProducts] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [api, setApi] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(null);

  // delete product
  const handleDeleteProduct = async (id, images) => {
    const shouldDelete = window.confirm("Are you sure to delete?");
    if (shouldDelete) {
      try {
        const res = await axios.post("/api/product/delete-product", {
          id,
          images,
        });
        if (res.data.success) {
          getAllProducts();
          message.success(res.data.message);
        } else {
          message.error(res.data.message);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      // User clicked "Cancel" or closed the dialog
    }
  };

  function handleClearFilter() {
    setProducts(data);
    setSearchQuery("");
    setApi("");
  }

  const handleSearch = () => {
    if (searchQuery.trim() === "" && api === "") {
      setFilteredUsers(null);
    } else {
      const filteredProducts = products?.filter((item) => {
        const nameMatch = item.name
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
        const apiMatch = api ? item.api === api : true;
        return nameMatch && apiMatch;
      });
      setFilteredUsers(filteredProducts);
    }
  };

  const getAllProducts = async () => {
    try {
      const res = await axios.get("/api/product/get-all-products");
      if (res.data.success) {
        setProducts(res.data.data.reverse());
        setData(res.data.data.reverse());
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleSearch();
  }, [searchQuery, api, products]);

  useEffect(() => {
    getAllProducts();
  }, []);

  const filterProduct =
    filteredUsers && filteredUsers ? filteredUsers : products;
  return (
    <AdminLayout>
      <div className="admin-users-container">
        <div className="page-title">
          <h3 className="m-0">Products</h3>
          <button onClick={() => navigate("/admin-add-product")}>
            Add New
          </button>
        </div>
        <hr />
        <div className="table-container">
          <div className="tools">
            <div className="form-fields">
              <input
                type="search"
                name="search"
                placeholder="Search by name"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div className="form-fields">
              <select
                value={api}
                onChange={(e) => setApi(e.target.value)}
                className="form-control"
                name="api"
              >
                <option value="">Select</option>
                <option value="yes">yes</option>
                <option value="no">no</option>
              </select>
            </div>
            <button className="btn btn-danger" onClick={handleClearFilter}>
              Clear Filter
            </button>
          </div>
          <table className="table user-table">
            <thead>
              <tr>
                <th>Image</th>
                <th>Name</th>
                <th>Stock</th>
                <th>Api</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filterProduct?.map((product, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <img
                        src={`https://redlineshop.in/${product?.images[0]}`}
                        alt=""
                      />
                    </td>
                    <td>
                      <small>{product?.name}</small>
                    </td>
                    <td>
                      <small>{product?.stock}</small>
                    </td>
                    <td>
                      <small>{product?.apiName || "NA"}</small>
                    </td>
                    <td>
                      <div className="d-flex gap-2">
                        <EditIcon
                          onClick={() =>
                            navigate(`/admin-edit-product/${product?._id}`)
                          }
                        />
                        <DeleteIcon
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleDeleteProduct(product?._id, product?.images)
                          }
                          className="text-danger"
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="pagination"></div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminProduct;
