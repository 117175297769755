import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import { message } from "antd";
import axios from "axios";
import "./Contact.css";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../redux/features/userSlice";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import WhatsApp from "@mui/icons-material/WhatsApp";

const Contact = () => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    name: "",
    email: "",
    mobile: "",
    msg: [],
  });
  const [error, setError] = useState(false);
  const [mapLoader, setMapLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setMapLoader(false);
    }, 1500);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "msg") {
      setForm((prevForm) => ({
        ...prevForm,
        msg: [
          {
            msg: value,
            person: "user",
          },
        ],
      }));
    } else {
      setForm({ ...form, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      form?.name === "" ||
      form?.email === "" ||
      form?.mobile === "" ||
      form?.msg.length === 0
    ) {
      setError(true);
      return;
    }
    try {
      const res = await axios.post("/api/contact/add-contact-form", form);
      if (res.data.success) {
        message.success(res.data.message);
        setForm({ name: "", email: "", mobile: "", msg: "" });
        setError(false);
      } else {
        message.error(res.data.message);
        setError(false);
      }
    } catch (error) {
      setError(false);
      console.log(error);
    }
  };
  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        dispatch(setUser(res.data.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <Layout>
      <div className="contact-page-container">
        <div className="row border rounded">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 contact-image">
            <span className="fs-4">
              <b>
                REDLINE<span className="ffca00">SHOP</span>
              </b>
            </span>
            <h6 className="mt-4">
              <b>Contact Us</b>
            </h6>
            <p>
              Activate your gaming experience with RedlineShop's official
              Contact! Reach out to us for a comprehensive solution to all your
              gaming queries, ensuring seamless gameplay and keeping the thrill
              alive.
            </p>
            <p>
              <b>Email:</b> submityourquerry@yahoo.com
            </p>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 contact-form">
            <h5>Feel free to message us!</h5>
            <div className="hr-line mb-4"></div>
            <form onSubmit={handleSubmit}>
              <div className="mb-2 form-fields">
                <label className="form-label" htmlFor="name">
                  Name
                </label>
                <input
                  onChange={handleChange}
                  name="name"
                  className="form-control py-1"
                  type="text"
                  value={form.name}
                  placeholder="Enter your name"
                />
                <span className="text-danger">
                  <small>
                    {error && form?.name === "" && "Please enter your name"}
                  </small>
                </span>
              </div>
              <div className="mb-2 form-fields">
                <label className="form-label" htmlFor="name">
                  Email
                </label>
                <input
                  onChange={handleChange}
                  name="email"
                  className="form-control py-1"
                  type="email"
                  value={form.email}
                  placeholder="Enter your email"
                />
                <span className="text-danger">
                  <small>
                    {error && form?.email === "" && "Please enter your email"}
                  </small>
                </span>
              </div>
              <div className="mb-2 form-fields">
                <label className="form-label" htmlFor="name">
                  Mobile
                </label>
                <input
                  onChange={handleChange}
                  name="mobile"
                  className="form-control py-1"
                  type="text"
                  value={form.mobile}
                  placeholder="Enter your phone number"
                />
                <span className="text-danger">
                  <small>
                    {error && form?.mobile === "" && "Please enter your mobile"}
                  </small>
                </span>
              </div>
              {/* <div className="mb-2 form-fields">
                <label className="form-label" htmlFor="name">
                  Query Type
                </label>
                <select
                  className="form-select"
                  name="type"
                  onChange={handleChange}
                  value={form?.type}
                >
                  <option value="">Select</option>
                  <option value="Payment Related Query">
                    Payment Related Queries
                  </option>
                  <option value="In-Game Recharge Query">
                    In-Game Recharge Query
                  </option>
                  <option value="Wanted to be a Reseller">
                    Wanted to be a Reseller
                  </option>
                  <option value="others">Other Query</option>
                </select>
                <span className="text-danger">
                  <small>
                    {error && form?.type === "" && "Select Query Type"}
                  </small>
                </span>
              </div> */}
              <div className="mb-2 form-fields">
                <label className="form-label" htmlFor="name">
                  Message
                </label>
                <textarea
                  onChange={handleChange}
                  className="form-control"
                  value={
                    form.msg.length > 0
                      ? form.msg[form.msg.length - 1].message
                      : ""
                  }
                  name="msg"
                  rows="3"
                  placeholder="How can we help you?"
                ></textarea>
                <span className="text-danger">
                  <small>
                    {error && form?.msg === "" && "Please enter your message"}
                  </small>
                </span>
              </div>
              <button type="submit" className="register-btn m-0">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
