import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import HeroSection from "../components/Home/HeroSection";
import HowItWorks from "../components/Home/HowItWorks";
import SliderText from "../components/Home/SliderText.js";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Games from "../components/Games";
import Testimonials from "../components/Home/Testimonial";

const Home = () => {
  const params = useParams();
  const navigate = useNavigate();

  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
        } else {
          // localStorage.removeItem("token");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserData();
    if (params.token) {
      localStorage.setItem("token", params.token);
      navigate("/user-dashboard");
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "Are you sure you want quit?";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <Layout>
      <HeroSection />
      <SliderText
        text={"CUSTOMER SERVICE 10-5PM"}
        direction={"left"}
        bg={"#ffca00"}
        fs={16}
      />
      <Games title={""} />
      <HowItWorks />
    </Layout>
  );
};

export default Home;
