import React from "react";
import Header from "../components/Header/Header";
import "./About.css";
import WeAre from "../components/About/WeAre";
import Text from "../components/About/Text";
import Layout from "../components/Layout/Layout";
import IMAGES from "../img/image";
import HowItWorks from "../components/Home/HowItWorks";

const About = () => {
  return (
    <Layout>
      <div className="container about-container">
        <p>
          "Welcome to RedlineShop, your ultimate destination for all your gaming
          top-up needs! At RedlineShop, we're dedicated to providing gamers with
          a seamless and convenient platform to enhance their gaming
          experiences. Whether you're diving into the latest AAA titles,
          conquering the competitive scene, or exploring the vast worlds of
          mobile gaming, we've got you covered.
        </p>

        <p>
          Our mission is simple: to offer a diverse range of top-up options for
          various gaming platforms, including popular titles such as Fortnite,
          League of Legends, PUBG, and many more. With secure payment options
          and instant delivery, topping up your in-game currency, subscriptions,
          or virtual items has never been easier.
        </p>

        <p>
          Driven by our passion for gaming and commitment to customer
          satisfaction, RedlineShop ensures a hassle-free experience from start
          to finish. Our user-friendly interface, responsive customer support,
          and competitive pricing make us the go-to destination for gamers
          seeking reliable top-up services.
        </p>

        <p>
          Join the RedlineShop community today and elevate your gaming journey
          to new heights. Whether you're a casual player or a hardcore
          enthusiast, let RedlineShop be your trusted partner in gaming
          excellence."
        </p>
      </div>
      <HowItWorks />
    </Layout>
  );
};

export default About;
