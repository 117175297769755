import React, { useState } from "react";
import Layout from "../components/Layout/Layout";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import "./Register.css";

const Register = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({});

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (form?.mobile?.length !== 10) {
      return message.error("Enter 10 digits Mobile Number only");
    }

    if (!validateEmail(form?.email)) {
      return message.error("Enter a valid email address");
    }

    try {
      const res = await axios.post("/api/user/register", form);
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/login");
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      message.error("An error occurred during registration");
    }
  };

  return (
    <Layout>
      <div className="container-fluid register-container bg-white">
        <div className="row text-center">
          <div className="d-block m-auto col-12 col-sm-12 col-md-6 col-lg-6">
            <form className="register-form" onSubmit={handleSubmit}>
              <h1>Create Account</h1>
              <div className="form-fields mb-3">
                <input
                  onChange={handleChange}
                  value={form?.fname}
                  name="fname"
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                />
              </div>
              <div className="form-fields mb-3">
                <input
                  onChange={handleChange}
                  value={form?.lname}
                  name="lname"
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                />
              </div>
              <div className="form-fields mb-3">
                <input
                  onChange={handleChange}
                  value={form?.email}
                  name="email"
                  type="email"
                  className="form-control"
                  placeholder="Email"
                />
              </div>
              <div className="form-fields mb-3">
                <input
                  onChange={handleChange}
                  value={form?.mobile}
                  name="mobile"
                  type="text"
                  className="form-control"
                  placeholder="Mobile"
                />
              </div>
              <div className="form-fields mb-3">
                <input
                  onChange={handleChange}
                  value={form?.password}
                  name="password"
                  type="text"
                  className="form-control"
                  placeholder="Password"
                />
              </div>
              <button className="register-btn">Create Now</button>
              <div className="forgot-pass d-flex justify-content-between">
                <h6 className="text-center my-2">
                  Already a Customer? <Link to="/login">Click here</Link>
                </h6>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Register;
