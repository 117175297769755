const IMAGES = {
  // LOGO
  //HOME
  b1: require("../img/home/1.png"),
  b2: require("../img/home/2.png"),
  b3: require("../img/home/3.png"),
  safe: require("../img/products/safe.png"),
  logo: require("../img/logo.png"),
  /// PHONE SKIN
  pbanner: require("../img/products/banner2.webp"),
  // barcode
  barcode: require("../img/barcode.jpg"),
  // HOME
  trusted: require("../img/home/trusted.svg"),
  register: require("./home/register.svg"),
  verified: require("./home/verified.svg"),
  privacy: require("./home/privacy.svg"),
};

export default IMAGES;
