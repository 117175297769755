import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import axios from "axios";
import { message } from "antd";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import "./AdminResellers.css";
import { useNavigate } from "react-router-dom";

const AdminResellers = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const [resellers, setResellers] = useState(null);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState(null);
  const [orderId, setOrderId] = useState("");

  async function handleGetResellers() {
    try {
      const res = await axios.get("/api/admin/resellers", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setResellers(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function handleGetResellerOrders(email) {
    try {
      setLoading(true);
      const res = await axios.post(
        "/api/admin/reseller-orders",
        { email: email },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        setOrders(res.data.data);
        setLoading(false);
        setTab(1);
      } else {
        message.error(res.data.message);
        setLoading(false);
        setTab(0);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      setTab(0);
    }
  }

  useEffect(() => {
    handleGetResellers();
  }, []);

  return (
    <AdminLayout>
      <div className="page-title">
        <h3 className="m-0">Resellers</h3>
      </div>
      <hr />
      {tab === 0 && (
        <div className="tools mb-3">
          <div className="">
            <input
              type="text"
              className="form-control"
              placeholder="Search by email"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
      )}
      {tab === 1 && (
        <div className="d-flex gap-2">
          <button className="btn btn-warning mb-3" onClick={() => setTab(0)}>
            Back
          </button>
          <div className="tools mb-3">
            <div className="">
              <input
                type="text"
                className="form-control"
                placeholder="Search Order ID"
                onChange={(e) => setOrderId(e.target.value)}
              />
            </div>
          </div>
        </div>
      )}
      {tab === 0 && (
        <div className="resellers-container">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Srno</th>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile</th>
                <th>Total Orders</th>
                <th>Total Sales</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {resellers
                ?.filter((item) =>
                  item?.email?.toLowerCase().includes(search?.toLowerCase())
                )
                .map((item, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item?.fname}</td>
                      <td>{item?.email}</td>
                      <td>{item?.mobile}</td>
                      <td>{item?.totalOrders}</td>
                      <td>{item?.totalSales}</td>
                      <td>
                        <button
                          className="btn btn-success"
                          onClick={() => handleGetResellerOrders(item?.email)}
                        >
                          View Orders
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
      {tab === 1 && (
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Order ID</th>
              <th>Order Type</th>
              <th>Email</th>
              <th>Total</th>
              <th>Status</th>
              <th>Date & Time</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {orders
              ?.filter((item) => item?.orderId.includes(orderId))
              .map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item?.orderId}</td>
                    <td>
                      {item?.api === "no" ? "Manual Order" : "Api Orders"}
                    </td>
                    <td>
                      <small>{item?.customer_email}</small>
                    </td>
                    <td>
                      <small>{item?.price}</small>
                    </td>
                    <td>
                      <small>{item?.status}</small>
                    </td>
                    <td>
                      <small>
                        {new Date(item?.createdAt).toLocaleString("default", {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                        })}
                      </small>
                    </td>
                    <td align="center">
                      <small>
                        <RemoveRedEyeIcon
                          onClick={() =>
                            navigate(`/admin-view-order/${item?.orderId}`)
                          }
                        />
                      </small>
                    </td>
                  </tr>
                );
              })}
            <tr></tr>
          </tbody>
        </table>
      )}
    </AdminLayout>
  );
};

export default AdminResellers;
