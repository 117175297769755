import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import "./ProductInfo.css";
import IMAGES from "../img/image";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import { useSelector } from "react-redux";
import CryptoJS from "crypto-js";
import HowItWorks from "../components/Home/HowItWorks";

const ProductInfo = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [playerCheck, setPlayerCheck] = useState(null);
  const [allowUser, setAllowUser] = useState(null);
  const [product, setProduct] = useState(0);
  const [showImage, setShowImage] = useState(0);
  const [error, setError] = useState(false);
  const [cart, setCart] = useState(
    JSON.parse(localStorage.getItem("cart")) || []
  );
  const [goToCart, setGoToCart] = useState(false);

  const secretKey = "REDLINE@#$123";
  const encryptPrice = (price) => {
    if (!secretKey) {
      console.error("Secret key is not defined");
      return price;
    }
    return CryptoJS.AES.encrypt(price.toString(), secretKey).toString();
  };

  //!NEW STATE
  const [playerId, setPlayerId] = useState("");
  const [amount, setAmount] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [productId, setProductId] = useState("");
  //! API BASED
  const [userId, setUserId] = useState("");
  const [zoneId, setZoneId] = useState("");

  function setPriceAndId(amount) {
    if (user?.reseller === "yes") {
      const price = product?.cost?.find(
        (item) => item.amount === amount
      )?.resPrice;
      setSelectedPrice(price);
      const id = product?.cost?.find((item) => item.amount === amount)?.id;
      setProductId(id);
    } else {
      const price = product?.cost?.find(
        (item) => item.amount === amount
      )?.price;
      setSelectedPrice(price);
      const id = product?.cost?.find((item) => item.amount === amount)?.id;
      setProductId(id);
    }
  }

  const getProduct = async () => {
    try {
      const res = await axios.post("/api/product/get-product-by-name", {
        name: params.name,
      });
      if (res.data.success) {
        setProduct(res.data.data);
        const defaultAmount = res.data.data?.cost?.[0]?.amount;
        const defaultPrice =
          user && user?.reseller === "yes"
            ? res.data.data?.cost?.[0]?.resPrice
            : res.data.data?.cost?.[0]?.price;
        const defaultId = res.data.data?.cost?.[0]?.id;
        setAmount(defaultAmount);
        setSelectedPrice(defaultPrice);
        setProductId(defaultId);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGoToCart = () => {
    if (cart.length > 0) {
      setGoToCart(true);
    }
  };

  useEffect(() => {
    handleGoToCart();
  }, [cart, product]);

  const clearCart = () => {
    setCart([]);
    setGoToCart(!goToCart);
    localStorage.removeItem("cart");
    setPlayerId("");
    setUserId("");
    setZoneId("");
  };

  const handleAddToCart = () => {
    if (product?.api === "yes") {
      if (product?.apiName === "smileOne") {
        if (!playerCheck) {
          return message.error("Check Player Name");
        }
        if (userId === "") {
          return message.error("Enter User ID");
        }
        if (zoneId === "") {
          return message.error("Enter Zone ID");
        }
      }
      if (product?.apiName === "moogold") {
        if (product?.gameName === "4427071") {
          if (userId === "") {
            return message.error("Enter Player ID");
          }
        }
        if (product?.gameName === "15145") {
          if (userId === "") {
            return message.error("Enter User ID");
          }
          if (zoneId === "") {
            return message.error("Enter Zone ID");
          }
          if (!playerCheck) {
            return message.error("Check Player Name");
          }
        }
        if (product?.gameName === "428075") {
          if (userId === "") {
            return message.error("Enter Player ID");
          }
          if (zoneId === "") {
            return message.error("Select Server");
          }
        }
      }
    } else {
      if (userId === "") {
        return alert("Enter User ID");
      }
    }

    const itemToAdd = {
      id: product?._id,
      productId: encryptPrice(productId),
      region: product?.region,
      api: product?.api,
      apiName: product?.apiName,
      gameName: product?.gameName,
      name: product?.name,
      price: encryptPrice(selectedPrice),
      images: product?.images,
      userId: userId.trim(),
      zoneId: zoneId.trim(),
      amount: amount,
      username: playerCheck,
    };
    // Check if the product is already in the cart
    const existingProductIndex = cart.findIndex(
      (item) =>
        item.id === itemToAdd.id &&
        item.selectedBrand === itemToAdd.selectedBrand &&
        item.selectedModel === itemToAdd.selectedModel
    );

    if (existingProductIndex !== -1) {
      // If the product already exists, update the quantity
      setCart((prevCart) => {
        const updatedCart = [...prevCart];
        localStorage.setItem("cart", JSON.stringify(updatedCart));
        return updatedCart;
      });
    } else {
      // If the product doesn't exist, add it to the cart
      setCart((prevCart) => {
        const updatedCart = [...prevCart, itemToAdd];
        // Store the updated cart in localStorage
        localStorage.setItem("cart", JSON.stringify(updatedCart));
        return updatedCart;
      });
    }
    alert("Item added to cart!");
  };

  useEffect(() => {
    getProduct();
    // eslint-disable-next-line
  }, [params?.name, user]);

  async function handleCheckPlayer() {
    if (userId === "" || zoneId === "") {
      return message.error(
        `${userId === "" ? "Enter user id" : "Enter zone id"}`
      );
    }
    try {
      setLoading(true);
      const idPro = productId.split("&")[0];
      const object = {
        userid: userId,
        zoneid: zoneId,
        apiName: product?.apiName,
      };
      const res = await axios.post("/api/payment/get-role", object);
      if (res.data.success) {
        setPlayerCheck(res.data.username);
        if (product?.use === res.data.use) {
          setAllowUser(true);
        }
        setLoading(false);
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <Layout>
      <div className="product-info-container">
        <div className="product-info-img order-2 order-lg-1">
          <div className="border rounded">
            <h5 className="m-0">Description:</h5>
            <span>{product?.desc}</span>
          </div>
          <div className="border rounded">
            <h5 className="m-0"></h5>
            <span>{product?.descTwo}</span>
          </div>
        </div>
        <div className="product-info-content order-1 order-lg-2 border mb-2">
          <h3 className="">{product?.name}</h3>
          {product?.apiName === "smileOne" ? (
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-4 mb-3">
                <input
                  className="form-control player-tag"
                  type="text"
                  name="userId"
                  placeholder="USER ID"
                  onChange={(e) => setUserId(e.target.value)}
                  value={userId}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4 mb-3">
                <input
                  className="player-tag form-control"
                  type="text"
                  name="zoneid"
                  placeholder="ZONE ID"
                  onChange={(e) => setZoneId(e.target.value)}
                  value={zoneId}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <button className="p-check-btn" onClick={handleCheckPlayer}>
                  Check
                  {loading && (
                    <div
                      className="spinner-grow spinner-grow-sm ms-2"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </div>
          ) : product?.apiName === "moogold" &&
            product?.gameName === "15145" ? (
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-4 mb-3">
                <input
                  className="form-control player-tag"
                  type="text"
                  name="userId"
                  placeholder="USER ID"
                  onChange={(e) => setUserId(e.target.value)}
                  value={userId}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4 mb-3">
                <input
                  className="player-tag form-control"
                  type="text"
                  name="zoneid"
                  placeholder="ZONE ID"
                  onChange={(e) => setZoneId(e.target.value)}
                  value={zoneId}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <button className="p-check-btn" onClick={handleCheckPlayer}>
                  Check
                  {loading && (
                    <div
                      className="spinner-grow spinner-grow-sm ms-2"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </div>
          ) : product?.apiName === "moogold" &&
            (product?.gameName === "428075" ||
              product?.gameName === "4233885") ? (
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <input
                  className="player-tag form-control"
                  type="text"
                  name="userId"
                  placeholder="USER ID"
                  onChange={(e) => setUserId(e.target.value)}
                  value={userId}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <select
                  name="zoneid"
                  className="form-select"
                  onChange={(e) => setZoneId(e.target.value)}
                >
                  <option value="">Select Server</option>
                  <option value="America">America</option>
                  <option value="Asia">Asia</option>
                  <option value="Europe">Europe</option>
                  <option value="TW, HK, MO">TW, HK, MO</option>
                </select>
              </div>
            </div>
          ) : product?.apiName === "moogold" &&
            (product?.gameName === "4427071" ||
              product?.gameName === "4427072" ||
              product?.gameName === "4427073" ||
              product?.gameName === "6963") ? (
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <input
                  className="form-control player-tag"
                  type="text"
                  name="userId"
                  placeholder={product?.tag ? product?.tag : "Enter ID"}
                  onChange={(e) => setUserId(e.target.value)}
                  value={userId}
                />
              </div>
            </div>
          ) : (
            <div className="row">
              <input
                className="form-control player-tag"
                type="text"
                name="userId"
                placeholder={product?.tag ? product?.tag : "Enter ID"}
                onChange={(e) => setUserId(e.target.value)}
                value={userId}
              />
            </div>
          )}
          {product?.apiName === "smileOne" && (
            <span className={`${allowUser ? "text-success" : "text-danger"}`}>
              {playerCheck && playerCheck}
            </span>
          )}
          {product?.apiName === "moogold" && (
            <span className="text-success">{playerCheck && playerCheck}</span>
          )}
          <div className="p-amount">
            {product?.cost?.map((item, index) => {
              return (
                <div
                  onClick={() => {
                    setAmount(item.amount);
                    setPriceAndId(item.amount);
                  }}
                  key={index}
                  className={`amount ${amount === item?.amount && "active"}`}
                >
                  <span>
                    <small>{item.amount}</small>
                    <small>
                      <b>
                        Rs.
                        {user?.reseller === "yes"
                          ? item?.resPrice
                          : item?.price}
                      </b>
                    </small>
                  </span>
                </div>
              );
            })}
          </div>

          {user?.block === "yes" || product?.stock === "no" ? (
            <button className="add-to-cart-btn" style={{ opacity: "0.7" }}>
              Out of Stock
            </button>
          ) : goToCart ? (
            <>
              <button
                onClick={() => navigate("/cart")}
                className="add-to-cart-btn"
              >
                Go To Cart
              </button>
              <button className="add-to-cart-btn bg-dark" onClick={clearCart}>
                Clear Cart
              </button>
            </>
          ) : product?.apiName === "smileOne" && !allowUser ? (
            <button className="p-check-btn disable">
              This ID is not Allowed
            </button>
          ) : (
            <button onClick={handleAddToCart} className="add-to-cart-btn">
              Add To Cart
            </button>
          )}
        </div>
      </div>
      <HowItWorks />
    </Layout>
  );
};

export default ProductInfo;
